import React from 'react';
import { Link } from "gatsby"
import Logo from "../images/connected.svg"

const Header = () => {
  return (
    <nav className="bg-zinc-100 text-black">
      <div className="">
        <div className="flex justify-between pl-5 md:px-0 border-b border-t md:border-t-0 border-gray-600">
          <div className="flex w-full md:px-5">
            <div className="flex-shrink-0 flex items-center">
                <Link to="/" itemProp="url"><img src={Logo} alt="Logo" className="w-10" /></Link>
                <Link to="/" itemProp="url" className="px-3 bg-zinc-100">
                    <h1 className="uppercase font-semibold">Web Design and eCommerce</h1>
                </Link>
            </div>
            <div className="w-full md:w-auto flex gap-8 items-center justify-center border-l md:border-r border-black">
              <Link to="/blog" className="text-sm bg-zinc-100 p-5">Blog</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
