import React from 'react';

const Footer = () => {
  return (
    <div className="border-t border-gray-600">
        <div className="container mx-auto py-5">
            <div className="px-5 text-xs md:flex md:items-center md:justify-between">
                <p className="">© {new Date().getFullYear()} <a href="https://www.bradient.com/" title="Web Design Los Angeles">BRADIENT</a>. All rights reserved.</p>
                <div className="mt-4 md:mt-0 md:flex md:items-center">
                    <a href="https://www.webdesignandecommerce.com/" title="Web Design and eCommerce" className="text-sm">Web Design and eCommerce</a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;
